<template>
  <main class="blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <header>
        <h2>מערכת ביטולים</h2>
        <div class="search-bar">
            <div class="search-input">
                <input class="input" placeholder="כל המשווקים" />
            </div>
            <div class="search-input">
                <input class="input" placeholder="בחר ספק" />
            </div>
            <div class="search-input">
                <input class="input" placeholder="בחר מוצר" />
            </div>
            <div class="search-input">
                <input class="input" placeholder="סטטוס" />
            </div>
            <div class="calendar-input">
                <Datepicker class="input tour-person__input cus-datepicker"/>
            </div>
            <div class="calendar-input">
                <Datepicker class="input tour-person__input cus-datepicker"/>
            </div>
            <div class="submit">
                <button>חפש</button>
            </div>
            <div/>
            <div/>
            <div/>
            <div/>
            <div/>

            <div class="checkbox">
                <p><input type="checkbox"/>הצג ביטולים במערכת בלבד</p>
                <p><input type="checkbox"/>הצג ביטולים פתוחים בלבד</p>
            </div>
        </div>
    </header>
    <div class="page-content">
        <v-data-table
                :headers="headers"
                :items="table"
                class="elevation-1"
                show-select
            >
                <template v-slot:items="props">
                    <tr>
                        <td>
                            <v-checkbox
                            :input-value="props.selected"
                            primary
                            hide-details
                            ></v-checkbox>
                        </td>
                        <td class="text-xs-right abc">{{ props.item.e }}</td>
                    </tr>
                    <!-- <td class="text-xs-right">{{ props.item.a }}</td> -->
                    <!-- <td class="text-xs-right">{{ props.item.c }}</td> -->
                    <!-- <td class="text-xs-right">{{ props.item.d }}</td> -->
                </template>
            </v-data-table>
        </div>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Datepicker from 'vuejs-datepicker';
import Loading from 'vue-loading-overlay';

export default {
  name: "MenuManagementCancelations",
  components: {
    Datepicker,
    Loading
  },
  computed: {
  },
  data() {
      let names = ["a","b","c","d", "e", "f","g", "m", "l", "n"]
      return {
        headers: ["מס.עסקה", "שם העסק", "מספר לקוח", "סוכןספק", "מוצר", "מחיר", "טלפון", "תאריך ושעת הטעינה", "תאריך ושעת הבקשה", "סטטוס", "סכום שבוטל"].map((i,idx) => ({text: i, value: names[idx] })),
        table: [{
            a: "1",
            b: "2",
            c: "3",
            d: "4",
            e: "5",
            f: "6",
            g: "7",
            h: "8"
        }]
      }
  }
}
</script>

<style lang="scss" scoped>
    h2 {
        text-align: center;
        padding: 16px 0;
    }
    .page-content {
        padding-left: 16px;
        background: white;
    }

    div.search-bar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 8px;
        margin: 16px 0;
        background: none;
    }

    div.search-input {
        position: relative;
        &:before {
            content: '';
            background: url('/assets/img/search.svg');
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
            width: 20px;
            height: 20px;
        }
        > input {
            padding: 0px;
            padding-left: 36px;
        }
    }

    div.calendar-input {
        position: relative;
        &:before {
            content: '';
            background: url('/assets/img/calendar-2.svg');
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 8px;
            width: 20px;
            height: 20px;
            z-index: 1;
        }
        > div.input {
            padding: 0px;
            padding-left: 36px;
        }
    }

    div.submit {
        button {
            padding: 8px 16px;
            background: #29abe2;
            border-radius: 6px;
            color: white;
        }
    }
</style>