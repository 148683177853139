var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"blank-aside-js",class:(_vm.isOpenAside=='true')?'blank-aside':''},[_c('header',[_c('h2',[_vm._v("מערכת ביטולים")]),_c('div',{staticClass:"search-bar"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"calendar-input"},[_c('Datepicker',{staticClass:"input tour-person__input cus-datepicker"})],1),_c('div',{staticClass:"calendar-input"},[_c('Datepicker',{staticClass:"input tour-person__input cus-datepicker"})],1),_vm._m(4),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_vm._m(5)])]),_c('div',{staticClass:"page-content"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.table,"show-select":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',[_c('td',[_c('v-checkbox',{attrs:{"input-value":props.selected,"primary":"","hide-details":""}})],1),_c('td',{staticClass:"text-xs-right abc"},[_vm._v(_vm._s(props.item.e))])])]}}])})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-input"},[_c('input',{staticClass:"input",attrs:{"placeholder":"כל המשווקים"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-input"},[_c('input',{staticClass:"input",attrs:{"placeholder":"בחר ספק"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-input"},[_c('input',{staticClass:"input",attrs:{"placeholder":"בחר מוצר"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-input"},[_c('input',{staticClass:"input",attrs:{"placeholder":"סטטוס"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit"},[_c('button',[_vm._v("חפש")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox"},[_c('p',[_c('input',{attrs:{"type":"checkbox"}}),_vm._v("הצג ביטולים במערכת בלבד")]),_c('p',[_c('input',{attrs:{"type":"checkbox"}}),_vm._v("הצג ביטולים פתוחים בלבד")])])
}]

export { render, staticRenderFns }